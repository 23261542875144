import React, { useEffect } from 'react';
import axios from 'axios';
import { generateToken } from '../utils/tokenUtils';
import { useNavigate } from 'react-router-dom';
import { useEmployee } from '../contexts/EmployeeContext';
import useTg from '../hooks/useTg';

const Auth = () => {
    const {actions } = useEmployee();
    const [infoText, setInfoText] = React.useState('Auth...');
    const navigate = useNavigate();
    const tg = useTg();


    useEffect(() => {
        async function getEmployee() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/employee/${tg?.initDataUnsafe.user?.id}`,{
                    headers: {
                        Authorization: `Bearer ${generateToken()}`,
                    },
                });
                if (response.data!==null){
                    if (response.data.position === 'pending'){
                        setInfoText('Your account is pending approval');
                    }else{
                        actions.setEmployeeData({id:response.data.id, name:response.data.name, position:response.data.position, wage:response.data.wage});
                        actions.setEmployeeStatus(response.data.status);
                        navigate('/main');
                    }
                }else
                    try {
                        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/employee/create`, {
                            telegram_id:  tg?.initDataUnsafe.user?.id,
                            name: tg?.initDataUnsafe.user?.username || tg?.initDataUnsafe.user?.first_name,
                        },
                        {headers: {
                            Authorization: `Bearer ${generateToken()}`,
                        },
                        });
                        setInfoText('Your account is pending approval');
                        
                    } catch (error) {
                        console.error('Error creating employee:', error );
                        setInfoText('Failed to create account');
                        throw error;
                    }
                }
             catch (error) {
                console.error('Error fetching employee:', error);
                throw error;
            }
        }

        if (tg?.initDataUnsafe?.user?.id){
            try {
                getEmployee();
            } catch (error) {
                console.log(error)
            }
        }
    }, [tg])

    return (
        <div className='wrap flex w-screen min-h-screen bg-tgBg justify-center font-Rubik flex-wrap content-center select-none pb-10'>
            <div className='text-3xl text-center text-tgText'> {infoText} </div>
        </div>
    );
};

export default Auth;