import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import { useEmployee } from '../contexts/EmployeeContext';
import { generateToken } from '../utils/tokenUtils';
import axios from 'axios';
import TimeRangeSlider from '../components/TimeRangeSlider';

function convertDecimalHours(decimalHours: any) {
    const hours = Math.floor(decimalHours); // Get the whole hours
    const minutes = Math.round((decimalHours - hours) * 60); // Convert the decimal part to minutes
    return `${hours} h ${minutes} m`;
}

const Stats = () => {
    const [stats, setStats] = useState<any>(null);
    const { employee } = useEmployee();
    const [range, setRange] = useState<"today" | "week" | "month" | "all_time">('all_time'); // Default to all_time

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/employee/${employee?.id}/stats`, {
                    headers: {
                        Authorization: `Bearer ${generateToken()}`,
                    },
                });
                setStats(response.data);
            } catch (error) {
                console.error('Error fetching stats data:', error);
            }
        };

        if (employee) {
            fetchStats();
        }
    }, [employee]);

    if (stats) {
        // Find the selected stats and all-time stats
        const selectedStats = stats.find((stat: any) => stat.period === range);
        const allTimeStats = stats.find((stat: any) => stat.period === 'all_time');

        return (
            <div className='wrap flex w-screen min-h-screen bg-tgBg justify-center font-Rubik flex-wrap content-start select-none pt-10 pb-10'>
                <Header title='Stats' showBackButton />
                <TimeRangeSlider onRangeChange={setRange} />
                <div className="info w-9/12 text-center mt-3 flex flex-wrap justify-start">
                    <div className='text-2xl w-full text-start text-tgText'>Wage: ${employee?.wage}</div>
                    <div className='text-2xl w-full text-start text-tgText'>
                        Time Worked: {convertDecimalHours(selectedStats?.hours_worked || 0)}
                    </div>
                    <div className='text-2xl w-full text-start text-tgText'>
                        Earned: ${Number(selectedStats?.total_earned || 0).toFixed(2)}
                    </div>
                    {range === 'all_time' && (
                        <>
                            <div className='text-2xl w-full text-start text-tgText'>
                                Paid: ${Number(allTimeStats?.total_paid || 0).toFixed(2)}
                            </div>
                            <div className='text-2xl w-full text-start text-tgText'>
                                Unpaid: ${Number((allTimeStats?.total_earned || 0) - (allTimeStats?.total_paid || 0)).toFixed(2)}
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    } else {
        return (
            <div className='wrap flex w-screen min-h-screen bg-tgBg justify-center font-Rubik flex-wrap content-start select-none pt-10 pb-10'>
                <Header title='Stats' showBackButton />
                <div className="info w-11/12 text-center">
                    Loading...
                </div>
            </div>
        );
    }
};

export default Stats;