import React from 'react';
import { useEmployee } from '../contexts/EmployeeContext';
import { log } from 'console';



const EmployeeStatusInfo: React.FC = () => {
    const { employee, schedule, status, actions, activeShift, activeBreak} = useEmployee();

    function isCurrentTimeInSchedule() {
        if (schedule) {
            const currentDate = new Date();
            const currentDateString = currentDate.toLocaleDateString('en-CA'); // Format: YYYY-MM-DD
            const currentTime = currentDate.getTime();
    
            const todaySchedules = schedule.filter(entry => {
                if (!entry.end_date) {
                    return currentDateString === entry.start_date;
                }
                return (
                    currentDateString >= entry.start_date &&
                    currentDateString <= entry.end_date
                );
            });
    
            if (todaySchedules.length === 0) return false;
    
            return todaySchedules.some(entry => {
                const startDateTime = new Date(currentDate);
                const endDateTime = new Date(currentDate);
                const parsedStartTime = parseTime(entry.start_time);
                const parsedEndTime = parseTime(entry.end_time);
    
                startDateTime.setHours(parsedStartTime.hours, parsedStartTime.minutes);
                endDateTime.setHours(parsedEndTime.hours, parsedEndTime.minutes);
    
                return currentTime >= startDateTime.getTime() && currentTime <= endDateTime.getTime();
            });
        } else {
            return false;
        }
    }


    function parseTime(timeStr: string) {
        const [time, modifier] = timeStr.split(' ');
        let [hours, minutes] = time.split(':').map(Number);
        if (modifier === 'PM' && hours < 12) hours += 12;
        if (modifier === 'AM' && hours === 12) hours = 0;
        return { hours, minutes };
    }


    if (!employee) {
        return null;
    }else {

        

    switch (status) {
        case 'idle': {
            if (schedule) {
                return (
                    <div className="info w-11/12 text-center">
                        <div className="w-full font-medium text-4xl text-tgHint">idle</div>
                        {isCurrentTimeInSchedule()===true
                        ? <div className="w-full font-medium text-xl text-green-400 mt-1">It’s your scheduled work time now</div>
                        : <div className="w-full font-medium text-xl text-orange-400 mt-1">It’s not your scheduled work time now</div>}
                        
                    </div>
                );
            }else{
                return null;
            }
        }
        case 'clocked_in': {
            if (activeShift) {
                const date = new Date(activeShift!.start_time).toLocaleTimeString().slice(0,5);
                console.log(date);
                return (
                    <div className="info w-11/12 text-center">
                        <div className="w-full font-medium text-4xl text-green-400">clocked in</div>
                        <div className={`w-full font-medium text-2xl text-green-400 mt-1`}>
                            since {date}
                        </div>
                    </div>
                );
            }else{
                return null;
            }
        }
        case 'on_break': {
            if (activeBreak) {
                const date = new Date(activeBreak.start_time).toLocaleTimeString().slice(0,5);
        
                return (
                    <div className="info w-11/12 text-center">
                        <div className="w-full font-medium text-4xl text-yellow-400">on break</div>
                        <div className="w-full font-medium text-2xl text-yellow-400 mt-1">
                            since {date}
                        </div>
                    </div>
                );
            } else {
                return null;
            }
        }
        default: {
            return null; 
        }
    }
    }
};

export default EmployeeStatusInfo;