import React from 'react';
import { useEmployee } from '../contexts/EmployeeContext';
import Header from '../components/Header';

const Schedule = () => {
    const { schedule } = useEmployee();

    const getDayOfWeek = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', { weekday: 'long' }); 
    };
    

    return (
        <div className='wrap flex w-screen min-h-screen bg-tgBg justify-center font-Rubik flex-wrap content-start select-none pt-10 pb-10'>
            <Header title='Schedule' showBackButton />
            <div className="info w-11/12 text-center">
                {schedule?.map((day: any, index: any) => (
                    <div key={index} className='w-full text-tgText font-medium text-2xl mt-1'>
                        {day.start_time} - {day.end_time}
                        <div className='text-sm text-gray-500'>
                            {day.start_date} ({getDayOfWeek(day.start_date)})
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Schedule;