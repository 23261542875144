import React, { useState } from 'react';

interface TimeRangeSliderProps {
    onRangeChange?: (range: 'today' | 'week' | 'month' | 'all_time') => void;
}

const TimeRangeSlider: React.FC<TimeRangeSliderProps> = ({ onRangeChange }) => {
    const [selected, setSelected] = useState<'today' | 'week' | 'month' | 'all_time'>('all_time'); // Default to all_time

    const handleClick = (range: 'today' | 'week' | 'month' | 'all_time') => {
        setSelected(range);
        if (onRangeChange) {
            onRangeChange(range); // Call the parent callback with the selected range
        }
    };

    return (
        <div className="flex bg-tgHint rounded-xl p-1 w-full max-w-xs">
            <button
                onClick={() => handleClick('today')}
                className={`flex-1 py-2 rounded-xl text-center font-medium ${
                    selected === 'today' ? 'bg-tgButton text-tgButtonText' : 'text-tgButtonText'
                }`}
            >
                Today
            </button>
            <button
                onClick={() => handleClick('week')}
                className={`flex-1 py-2 rounded-xl text-center font-medium ${
                    selected === 'week' ? 'bg-tgButton text-tgButtonText' : 'text-tgButtonText'
                }`}
            >
                Week
            </button>
            <button
                onClick={() => handleClick('month')}
                className={`flex-1 py-2 rounded-xl text-center font-medium ${
                    selected === 'month' ? 'bg-tgButton text-tgButtonText' : 'text-tgButtonText'
                }`}
            >
                Month
            </button>
            <button
                onClick={() => handleClick('all_time')}
                className={`flex-1 py-2 rounded-xl text-center font-medium ${
                    selected === 'all_time' ? 'bg-tgButton text-tgButtonText' : 'text-tgButtonText'
                }`}
            >
                All Time
            </button>
        </div>
    );
};

export default TimeRangeSlider;